import getConfig from 'next/config';
import {AddToListTypes} from '@hy-vee/react-web-and-mobile-ui-components';

export const getReservationStepperUrl = () => {
    const {hyveeBaseUrl} = getConfig().publicRuntimeConfig;

    return `${hyveeBaseUrl}/aisles-online/reservation-stepper`;
};

export const getReservationDrawerUrl = () => {
    const {hyveeBaseUrl} = getConfig().publicRuntimeConfig;

    return `${hyveeBaseUrl}/aisles-online/reservation-drawer`;
};

export const getShippingPreferenceDrawerUrl = () => {
    const {hyveeBaseUrl} = getConfig().publicRuntimeConfig;

    return `${hyveeBaseUrl}/aisles-online/shipping-preference-drawer`;
};

export const getCouponDetailsUrl = (couponId) => {
    const {hyveeBaseUrl} = getConfig().publicRuntimeConfig;

    return `${hyveeBaseUrl}/aisles-online/coupons/${couponId}`;
};

export const getAddToListUrl = (
    id,
    type,
    text,
) => {
    const {hyveeBaseUrl} = getConfig().publicRuntimeConfig;
    const listUrl = `${hyveeBaseUrl}/aisles-online/lists/modal/add-to-lists`;

    if (type === AddToListTypes.PRODUCT) {
        return `${listUrl}/product/${id}`;
    }

    if (text) {
        const textTolistUrl = `${hyveeBaseUrl}/aisles-online/lists/modal/add-text-to-list`;

        return `${textTolistUrl}?listItemsToAdd=${encodeURIComponent(`["${text}"]`)}`;
    }

    return listUrl;
};
