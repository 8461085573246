import {getShippingPreferenceDrawerUrl} from '../../utils/navigation-helpers';

import {IFrameModal} from './iframe-modal';

export const ShippingPreferenceModal = ({isVisible, onClose}) => {
    const iframeUrl = getShippingPreferenceDrawerUrl();

    const handleMessage = (message) => {
        if (message === 'cancel-shipping-preference-drawer') {
            onClose();
        }
    };

    return (
        <IFrameModal
            ariaLabel="Shipping preference drawer"
            onMessage={handleMessage}
            showModal={isVisible}
            url={iframeUrl}
        />
    );
};
