const graphqlFetch = (url, options) =>
    fetch(url, {
        ...options,
        headers: {
            'content-type': 'application/json',
        },
    }).then((response) => {
        if (!response.ok) {
            const err = new Error(`Error fetching ${url}`);

            err.response = response;

            throw err;
        }

        return response;
    });

export default graphqlFetch;
