import {getCouponDetailsUrl} from '../../utils/navigation-helpers';

import {IFrameModal} from './iframe-modal';

const CouponModal = ({isVisible, onClose, couponId}) => {
    const handleMessage = (message) => {
        if (message === 'close-coupon-modal') {
            onClose();
        }
    };

    return (
        <IFrameModal
            ariaLabel="View Coupon Details"
            onMessage={handleMessage}
            showModal={isVisible}
            url={getCouponDetailsUrl(couponId)}
        />
    );
};

export default CouponModal;
