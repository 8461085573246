import {useEffect} from 'react';
import IframeResizer from 'iframe-resizer-react';

const containerStyle = {
    bottom: 0,
    height: 'auto',
    left: 0,
    overflowY: 'auto',
    position: 'fixed',
    right: 0,
    top: 0,
    width: '100%',
    zIndex: 10_000,
};

const iFrameStyle = {
    maxHeight: '100%',
    minHeight: '100%',
    minWidth: '80%',
};

export const IFrameModal = ({ariaLabel, url, showModal, onMessage}) => {
    useEffect(() => {
        document.documentElement.style.overflow = showModal ? 'hidden' : 'visible';
    }, [showModal]);

    return showModal ? (
        <div style={containerStyle}>
            <IframeResizer
                aria-label={ariaLabel}
                data-testid="product-card-modal"
                heightCalculationMethod="max"
                inPageLinks
                onMessage={(messageData) => {
                    if (messageData?.message) {
                        onMessage(messageData.message);
                    }
                }}
                src={url}
                style={iFrameStyle}
            />
        </div>
    ) : null;
};
