import gql from 'graphql-tag';

export const cartItemFragment = gql`
    fragment ICartItem on cartItem {
        cartItemId
        cartId
        quantity
        quantityType
        storeProduct {
            storeProductId
            product {
                averageWeight
                productId
            }
        }
    }
`;

export const cartItemQuantityMutateFragment = gql`
    fragment ICartItemQuantityUpdate on cartItem {
        cartItemId
        quantity
    }
`;

export const cartItemDeleteCartLookupFragment = gql`
    fragment ICartItemDeleteCartLookupFragment on cartItem {
        cartItemId
        cartId
    }
`;
