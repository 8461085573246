import gql from 'graphql-tag';

import {cartItemFragment} from '../fragments/cart-fragments';

export const getAislesOnlineCart = gql`
    query GetAislesOnlineCart(
        $customerId: Int!
    ) {
        carts(customerId: $customerId, where: {isActive: true}) {
            cartId
            cartItems {
                ...ICartItem
            }
            deliveryAddress {
                firstName
                addressOne
                city
                state
                zip
            }
            fulfillmentExpiration,
            fulfillmentWindowStart
            fulfillmentWindowEnd
            fulfillmentType
            pickupLocation {
                name
            }
            store {
                legacyStoreId
                storeId
                name
                state
            }
        }
    }
    ${cartItemFragment}
`;
