import {reportAddToListClick} from '../../services/analytics-service';
import {getAddToListUrl} from '../../utils/navigation-helpers';

import {IFrameModal} from './iframe-modal';

const AddToListModal = ({isVisible, onClose, id, text, type}) => {
    const handleMessage = (message) => {
        if (message === 'aisles-online-iframe-modal-ready') {
            reportAddToListClick(id, type);
        } else if (message === 'close-lists-modal') {
            onClose();
        }
    };

    return (
        <IFrameModal
            ariaLabel="Add or Remove Items from List"
            onMessage={handleMessage}
            showModal={isVisible}
            url={getAddToListUrl(id, type, text)}
        />
    );
};

export default AddToListModal;
