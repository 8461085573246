import {useEffect} from 'react';
import {NetworkStatus, useMutation, useQuery} from '@apollo/client';

import {getAislesOnlineCart} from '../graphql/queries/cart-queries';
import {createCartMutation} from '../graphql/mutations/cart-mutations';
import {graphqlClient} from '../graphql/graphql-client';

let isCreatingNewCart = false;

const useCreateCartIfNeeded = (customerId, data, error, loading, networkStatus, refetch, createCartIfNeeded) => {
    const [createCart] = useMutation(createCartMutation, {
        onCompleted: () => {
            refetch();
        },
    });

    const isRefetching = networkStatus === NetworkStatus.refetch;
    const hasActiveCart = Boolean(data?.carts?.length);
    const shouldCreateNewCart =
        customerId &&
        !isCreatingNewCart &&
        !isRefetching &&
        !loading &&
        !error &&
        !hasActiveCart &&
        createCartIfNeeded;

    useEffect(() => {
        const createCartIfNecessary = async () => {
            if (shouldCreateNewCart) {
                isCreatingNewCart = true;

                await createCart({
                    variables: {
                        createCartInput: {
                            customerId: Number(customerId),
                        },
                    },
                });

                isCreatingNewCart = false;
            }
        };

        createCartIfNecessary();
    }, [createCart, customerId, shouldCreateNewCart]);
};

export const useAislesOnlineCart = (customer, createCartIfNeeded = false) => {
    const cache = graphqlClient();

    const customerId = customer?.customerId;
    const {data, loading, error, networkStatus, refetch} = useQuery(getAislesOnlineCart, {
        fetchPolicy: 'cache-first',
        notifyOnNetworkStatusChange: true,
        onCompleted: (finalData) => {
            cache.writeQuery({
                data: finalData,
                query: getAislesOnlineCart,
            });
        },
        skip: !customerId,
        ssr: false,
        variables: {
            customerId: Number(customerId),
        },
    });

    useCreateCartIfNeeded(customerId, data, error, loading, networkStatus, refetch, createCartIfNeeded);

    return {
        data: data?.carts?.[0] || null,
        loading,
        refetch,
    };
};
