import {ApolloLink, HttpLink, split} from '@apollo/client';
import getConfig from 'next/config';

import {SANITY_STRATEGY} from '../enums/graphql-strategies';
import {isServerSide} from '../lib/env';
import {TWO_LEGGED_AUTH_TYPE} from '../enums/auth-types';

import graphqlFetch from './graphql-fetch-service';

const getAbsoluteUrl = (url) => {
    if (isServerSide()) {
        return `http://localhost:3000${url}`;
    }

    return `${window.location.origin}${url}`;
};

const getSanityUrl = () => {
    const {publicRuntimeConfig} = getConfig();

    return publicRuntimeConfig.sanityGraphQlUrl;
};

export const getHeadersMiddleware = (ssrContext) => {
    const cookie = !ssrContext ? {} : {cookie: ssrContext.req.headers.cookie};

    return new ApolloLink((operation, forward) => {
        operation.setContext(({headers = {}}) => ({
            headers: {
                ...headers,
                ...cookie,
            },
        }));

        return forward(operation);
    });
};

export const getHttpLink = () => {
    const httpOptions = {
        credentials: 'same-origin',
        fetch: graphqlFetch,
    };

    return split(
        (operation) => operation.variables.clientStrategy === SANITY_STRATEGY,
        new HttpLink({
            uri: getSanityUrl(),
        }),
        split(
            (operation) => operation.variables.authType === TWO_LEGGED_AUTH_TYPE,
            new HttpLink({
                ...httpOptions,
                uri: getAbsoluteUrl('/perks/api/graphql/two-legged'),
            }),
            new HttpLink({
                ...httpOptions,
                uri: getAbsoluteUrl('/perks/api/graphql/three-legged'),
            }),
        ),
    );
};
