import {useContext} from 'react';

import FeatureToggleContext from './context';

const useFeatureToggle = () => {
    const featureToggleContext = useContext(FeatureToggleContext);

    if (!featureToggleContext) {
        throw new Error('Feature Toggle Hooks must be inside the Feature Toggle Provider');
    }

    return featureToggleContext;
};

export {useFeatureToggle};
