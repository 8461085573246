import {AddToListTypes} from '@hy-vee/react-web-and-mobile-ui-components';
import {getFormattedPrice} from '@hy-vee/shared-utils-pricing';

const pushToDataLayer = (event) => {
    const windowDataLayer = global.dataLayer;

    if (windowDataLayer) {
        windowDataLayer.push(event);
    }
};

export const reportAddToListClick = (id, type) => {
    let typeText = '';

    if (type === AddToListTypes.PRODUCT) {
        typeText = 'product';
    }

    return pushToDataLayer({
        event: 'addToListClick',
        gaEventAction: 'addToListClick',
        gaEventCategory: 'Ecommerce',
        gaEventLabel: `Clicked add to list${typeText ? ` on ${typeText}: ${id || ''}` : ''} from ${window.location.pathname}`,
    });
};

export const addToCartDataLayer = (product, storeProduct, quantity) => {
    if (!product || !storeProduct) {
        return;
    }

    const price = getFormattedPrice({
        ...product,
        ...storeProduct,
    });

    pushToDataLayer({
        ecommerce: {
            add: {
                products: [{
                    brand: '',
                    category: '',
                    id: product.productId,
                    name: product.name,
                    price,
                    quantity,
                }],
            },
        },
        event: 'addToCart',
        gaEventAction: String(window.location.pathname),
        gaEventCategory: 'Ecommerce',
        gaEventLabel: `Adding to cart from ${window.location.pathname}`,
    });
};

export const removeFromCartDataLayer = (product, quantity) => {
    if (!product) {
        return;
    }

    pushToDataLayer({
        ecommerce: {
            remove: {
                products: [{
                    brand: '',
                    category: '',
                    id: product.productId,
                    name: product.name,
                    price: product.price,
                    quantity,
                }],
            },
        },
        event: 'removeFromCart',
        gaEventAction: String(window.location.pathname),
        gaEventCategory: 'Ecommerce',
        gaEventLabel: `Removing from cart from ${window.location.pathname}`,
    });
};
