import {useCallback, useMemo} from 'react';
import {useQuery} from '@apollo/client';
import getConfig from 'next/config';

import * as featuresToggleNames from '../enums/feature-toggle-names';
import {getUnleashFeatures} from '../graphql/queries/customer-and-feature-queries';
import {THREE_LEGGED_AUTH_TYPE, TWO_LEGGED_AUTH_TYPE} from '../enums/auth-types';

import FeatureToggleContext from './context';

export const allFeatures = Object.values(featuresToggleNames);

const buildDefaultMap = () => new Map(allFeatures.map((feature) => [feature, false]));

const FeatureToggleProvider = ({children, isAuthenticated}) => {
    const store = getConfig().publicRuntimeConfig.defaultStoreId;

    const {
        data,
        loading,
        error,
    } = useQuery(getUnleashFeatures, {
        variables: {
            authType: isAuthenticated ? THREE_LEGGED_AUTH_TYPE : TWO_LEGGED_AUTH_TYPE,
            featureNames: allFeatures,
            properties: {
                storeCode: store.toString(),
            },
        },
    });

    const featureMap = useMemo(() => {
        if (!loading && data && data.unleashFeatureToggles) {
            const featureToggles = data.unleashFeatureToggles;

            return new Map(
                featureToggles.map(({active, featureName}) => [featureName, active]),
            );
        }

        return buildDefaultMap();
    }, [data, loading]);

    const featureEnabled = useCallback((feature) => Boolean(featureMap.get(feature)), [featureMap]);

    const contextValue = useMemo(() => ({
        error,
        featureEnabled,
        featureMap,
        loading,
    }), [featureEnabled, error, featureMap, loading]);

    return (
        <FeatureToggleContext.Provider value={contextValue}>
            {children}
        </FeatureToggleContext.Provider>
    );
};

export default FeatureToggleProvider;
