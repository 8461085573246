import {createContext, useState, useContext, useMemo} from 'react';

import {StoreSelectionModal} from './store-selection-modal';
import AddToListModal from './add-to-list-modal';
import CouponModal from './coupon-modal';
import {ShippingPreferenceModal} from './shipping-preference-modal';

const ModalContext = createContext(undefined);

export const useModalContext = () => {
    const context = useContext(ModalContext);

    if (!context) {
        throw new Error(
            'Unable to create modal context, please ensure your component is within the ModalProvider',
        );
    }

    return context;
};

const setVisibilityWrapper = (update, current, set) => {
    if (update !== current) {
        set(update);
    }
};

const ModalProvider = ({children}) => {
    const [isStoreSelectorVisible, setIsStoreSelectorVisible] = useState(false);
    const [
        isShippingPreferenceDrawerVisible,
        setIsShippingPreferenceDrawerVisible,
    ] = useState(false);
    const [addToListState, setAddToListState] = useState(null);
    const [couponId, setCouponId] = useState(null);

    const value = useMemo(
        () => ({
            addToListModal: {
                addText: (text) =>
                    setAddToListState({
                        text,
                    }),
                addToList: (type, id) =>
                    setAddToListState({
                        id,
                        type,
                    }),
                hide: () => setAddToListState(null),
                isVisible: Boolean(addToListState),
            },
            couponModal: {
                hide: () => setCouponId(null),
                isVisible: Boolean(couponId),
                show: (id) => setVisibilityWrapper(id, couponId, setCouponId),
            },
            shippingPreferenceDrawer: {
                hide: () =>
                    setVisibilityWrapper(
                        false,
                        isShippingPreferenceDrawerVisible,
                        setIsShippingPreferenceDrawerVisible,
                    ),
                isVisible: isShippingPreferenceDrawerVisible,
                show: () =>
                    setVisibilityWrapper(
                        true,
                        isShippingPreferenceDrawerVisible,
                        setIsShippingPreferenceDrawerVisible,
                    ),
            },
            storeSelector: {
                hide: () =>
                    setVisibilityWrapper(
                        false,
                        isStoreSelectorVisible,
                        setIsStoreSelectorVisible,
                    ),
                isVisible: isStoreSelectorVisible,
                show: () =>
                    setVisibilityWrapper(
                        true,
                        isStoreSelectorVisible,
                        setIsStoreSelectorVisible,
                    ),
            },
        }),
        [
            couponId,
            isStoreSelectorVisible,
            addToListState,
            isShippingPreferenceDrawerVisible,
        ],
    );

    return (
        <ModalContext.Provider value={value}>
            {children}
            <StoreSelectionModal
                isVisible={isStoreSelectorVisible}
                onClose={() => setIsStoreSelectorVisible(false)}
            />
            <CouponModal
                couponId={couponId}
                isVisible={Boolean(couponId)}
                onClose={() => setCouponId(null)}
            />
            <AddToListModal
                id={addToListState?.id}
                isVisible={Boolean(addToListState)}
                onClose={() => setAddToListState(null)}
                text={addToListState?.text}
                type={addToListState?.type}
            />
            <ShippingPreferenceModal
                isVisible={isShippingPreferenceDrawerVisible}
                onClose={() => setIsShippingPreferenceDrawerVisible(false)}
            />
        </ModalContext.Provider>
    );
};

export default ModalProvider;
