import gql from 'graphql-tag';

export const getCustomerAndCartStoreData = gql`
    query getCustomerAndCartStoreData($customerUuid: String!, $customerId: Int!) {
        customer(customerUuid: $customerUuid) {
            customerUuid
            customerId
            firstName
            fuelSaverCardUuid
        }
        carts(customerId: $customerId, where: {isActive: true}) {
            cartId
            store {
                storeId
                nickname
                name
            }
        }
    }
`;

export const getUnleashFeatures = gql`
    query GetUnleashFeatureToggles($featureNames: [String!]!, $properties: UnleashProperties) {
        unleashFeatureToggles(featureNames: $featureNames, properties: $properties) {
            active
            featureName
        }
    }
`;
