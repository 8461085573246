import {useAislesOnlineCart} from '../../hooks/use-aisles-online-cart';
import {getReservationStepperUrl, getReservationDrawerUrl} from '../../utils/navigation-helpers';
import {useFeatureToggle} from '../../feature-toggle';
import {AO_RESERVATION_DRAWER} from '../../enums/feature-toggle-names';

import {IFrameModal} from './iframe-modal';

export const StoreSelectionModal = ({isVisible, onClose}) => {
    const {refetch: refetchCart} = useAislesOnlineCart();
    const {featureEnabled} = useFeatureToggle();
    const isReservationDrawerEnabled = featureEnabled(AO_RESERVATION_DRAWER);
    const iframeUrl = isReservationDrawerEnabled ? getReservationDrawerUrl() : getReservationStepperUrl();

    const handleMessage = (message) => {
        if (message === 'cancel-reservation-stepper') {
            if (refetchCart) {
                refetchCart();
            }

            onClose();
        }
    };

    return (
        <IFrameModal
            ariaLabel="Store Selection"
            onMessage={handleMessage}
            showModal={isVisible}
            url={iframeUrl}
        />
    );
};
